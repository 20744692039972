<template>
  <div>
    <b-overlay :show="isLoading">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form inline>
              <b-form-group
                label="Time range"
                label-sr-only
              >
                <date-range-picker
                  v-model="timeRange"
                  :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days']"
                  :max-selectable-days="2000"
                  :timerange="asup.timerange"
                />
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="errorsOnly"
                >
                  Errors only
                </b-form-checkbox>
              </b-form-group>
            </b-form>
          </b-card>
        </b-col>
      </b-row>

      <Grid
        class="grid-full-height"
        :data-items="result"
        :columns="columns"
        :column-menu="true"
        :take="dataState.take"
        :skip="dataState.skip"
        :sort="dataState.sort"
        :filter="dataState.filter"
        :group="dataState.group"
        :sortable="true"
        :reorderable="true"
        :resizable="true"
        :groupable="false"
        :pageable="true"
        :filterable="true"
        :page-size="50"
        @datastatechange="onDataStateChange"
        @filterchange="onFilterChange"
        @sortchange="onSortChange"
        @columnreorder="onColumnReorder"
      >
        <template v-slot:codeCell="{ props }">
          <AvamarEventCodeCellTemplate
            :id="props.dataItem.id"
            :field="props.field"
            :row-type="props.rowType"
            :event-code="props.dataItem.code"
            :event-remedy="props.dataItem.remedy"
            :event-description="props.dataItem.description"
          />
        </template>
      </Grid>
    </b-overlay>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BFormCheckbox, BForm, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import { Grid } from '@progress/kendo-vue-grid'
import { process } from '@progress/kendo-data-query'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import AvamarService from '@/service/avamar.service'
import moment from '@/libs/moment'
import {
  GridDefaultMixin, GridPreserveStateMixin,
} from '@/mixins/grid'
import AvamarEventCodeCellTemplate from '@/views/asup/avamar/components/AvamarEventCodeCellTemplate.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BForm,
    BFormGroup,
    DateRangePicker,
    Grid,
    BOverlay,
    AvamarEventCodeCellTemplate,
  },
  mixins: [
    GridDefaultMixin,
    GridPreserveStateMixin,
  ],
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        range: 'last7Days',
      },
      errorsOnly: false,
      isLoading: false,
      gridId: 'avamar_events',
      columns: [
        {
          field: 'localTimestamp',
          title: 'Timestamp',
          orderIndex: 1,
          width: '200px',
          filter: 'date',
          type: 'date',
          format: '{0:g}',
        },
        {
          field: 'domain',
          title: 'Domain',
          orderIndex: 2,
          width: '150px',
        },
        {
          field: 'type',
          title: 'Type',
          orderIndex: 3,
          width: '150px',
        },
        {
          field: 'category',
          title: 'Category',
          orderIndex: 4,
          width: '150px',
        },
        {
          field: 'severity',
          title: 'Severity',
          orderIndex: 5,
          width: '150px',
        },
        {
          field: 'summary',
          title: 'Summary',
          orderIndex: 6,
          width: '250px',
        },
        {
          field: 'code',
          title: 'Code',
          cell: 'codeCell',
          orderIndex: 7,
          width: '150px',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    result() {
      this.dataItems.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.localTimestamp = this.$options.filters.formatDateTimeTz(item.timestamp, this.asup.timezone)
      })

      return process(this.dataItems, this.dataState)
    },
  },
  watch: {
    timeRange() {
      this.loadEvents()
    },
    errorsOnly() {
      this.loadEvents()
    },
  },
  beforeMount() {
    if (this.isCurrentUserHost === true) {
      this.errorsOnly = true
    }
  },
  mounted() {
    this.loadEvents()
  },
  methods: {
    loadEvents() {
      this.isLoading = true
      AvamarService.getAllEventListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).subtract(1, 'days').format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).add(1, 'days').format('YYYY-MM-DD'),
        maxResultCount: 10000,
        onlyErrors: this.errorsOnly,
      }, { disableTenantFilter: true })
        .then(result => {
          this.dataItems = result
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}

</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
