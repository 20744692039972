import { render, staticRenderFns } from "./AvamarDetailEvents.vue?vue&type=template&id=80a9bf18&scoped=true&"
import script from "./AvamarDetailEvents.vue?vue&type=script&lang=js&"
export * from "./AvamarDetailEvents.vue?vue&type=script&lang=js&"
import style0 from "./AvamarDetailEvents.vue?vue&type=style&index=0&id=80a9bf18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80a9bf18",
  null
  
)

export default component.exports