<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    <feather-icon
      v-b-popover.hover.html="`<strong><u>Description:</u></strong><br/>${eventDescription}<br/><br/><strong><u>Remedy:</u></strong><br/>${eventRemedy}`"
      icon="HelpCircleIcon"
      size="16"
      title="Details"
    />
    {{ eventCode }}
  </td>
</template>

<script>

import { VBPopover } from 'bootstrap-vue'

export default {
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    eventCode: {
      type: String,
      default: '',
    },
    eventRemedy: {
      type: String,
      default: '',
    },
    eventDescription: {
      type: String,
      default: '',
    },
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
  },
}
</script>
